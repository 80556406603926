<template>
  <v-app>
    <v-main>
      <Content v-if="this.companyIndustry == 'F&B' && this.company_found ==true" />
      <otherService v-if="this.companyIndustry != 'F&B' && this.company_found ==true"/>
    </v-main>
  </v-app>
</template>


<script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import otherService from "@/components/otherService";
import Content from "@/components/Content";

export default {
  name: "Home",

  components: {
    Content,
    otherService,
  },
  data: () => ({
    domain: BASEURL,
    company_id: "",
    companyIndustry: "",
    url: "",
    company_name: "",
    company_found : false,
  
  }),

  computed: {},
  created() {
    this.url = this.$route.params.name;
    this.findCompanyByName();

  },
  watch: {},
  methods: {
    findCompanyByName(){
      const params = new URLSearchParams();
      params.append("getCompanyByURL", "done");
      params.append("url", this.url);
      axios({
        method: "post",
        url: this.domain + "/company/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.companyIndustry = response.data.company[0].industry;
            this.company_name = response.data.company[0].company_name;
            this.company_found = true;
          } else {

            window.location.replace("https://web.ebooking.today/");

          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>
